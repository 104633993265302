<!--
 * @Description: 地址编辑
 * @Author: zhang zhen
 * @Date: 2023-06-15 17:39:42
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-06 21:55:40
 * @FilePath: /page-sass/src/views/businessManagement/companyInfo/modules/addressEditor.vue
-->

<template>
  <DrawerView :visible="visible" :width="640" title="修改公司地址" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model ref="basicForm" :model="businessInfoDto" :label-col="labelCol" :wrapper-col="wrapperCol"
      :colon="false">

      <a-form-model-item label="公司地址" :labelCol="{ span: 3 }" :wrapperCol="{ span: 20 }">
        <ProvincePicker v-model="businessInfoDto.addressCode" style="width: 416px;"
          @updateName="handleChangeAddressCode" />
      </a-form-model-item>
      <a-form-model-item label="公司地址" :labelCol="{ span: 3 }" :wrapperCol="{ span: 20 }">
        <AddressPicker ref="MapPickerRef" map-key="f49ff6740fe5c943d85f87bc114146e0" v-model="businessInfoDto.address"
          @done="onChoose" />
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk('save')" :loading="loading" type="primary">确定</a-button>
    </template>
    <!-- <MapPicker
      ref="MapPickerRef"
      map-key="f49ff6740fe5c943d85f87bc114146e0"
      :need-city="true"
      :lock-scroll="false"
      @done="onChoose"
    /> -->
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postQueryAction, postAction } from '@/api/manage'
import ProvincePicker from './provincePicker.vue'
import AddressPicker from "./addressPicker.vue";

export default {
  name: 'moreInfoForm',
  components: {
    DrawerView,
    ProvincePicker,
    AddressPicker,
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      businessInfoDto: {},
      info: {},
      basicData: {}
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChooseAddress() {
      const { provinceName, cityName } = this.businessInfoDto
      let city = ''
      if (cityName) {
        city = provinceName == '北京市' || provinceName == '上海市' || provinceName == '重庆市' || provinceName == '天津市' ? provinceName : cityName
      }
      this.$refs.MapPickerRef.onOpen(city)
    },
    /**
 * @description: 更新code
 * @param {*} e
 * @return {*}
 */
    handleChangeAddressCode({ nameArr, codeArr }) {
      const [provinceName, cityName, areaName] = nameArr;
      const [provinceCode, cityCode, areaCode] = codeArr;

      this.businessInfoDto.provinceName = provinceName || null // 省
      this.businessInfoDto.cityName = cityName || null
      this.businessInfoDto.areaName = areaName || null

      this.businessInfoDto.provinceCode = provinceCode || null // 省
      this.businessInfoDto.cityCode = cityCode || null
      this.businessInfoDto.areaCode = areaCode || null
      this.$nextTick(() => {
        this.handleChooseAddress()
      })
    },
    onChoose(position) {
      this.$set(this.businessInfoDto, 'position', lng + ',' + lat)
    },
    handleEdit(item) {
      item && (this.basicData = { ...item })
      const { tradeIdentity } = this.setUserInfo()
      this.tradeIdentity = tradeIdentity
      this.handleInitData()
      this.visible = true
    },
    handleInitData() {
      if (!this.setUserInfo().businessId) return
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then(res => {
        const { success, data } = res
        if (success) {
          this.businessInfoDto = data
          const { provinceCode, cityCode, areaCode, } = data;
          let codeArr = [];
          provinceCode && codeArr.push(provinceCode)
          cityCode && codeArr.push(cityCode)
          areaCode && codeArr.push(areaCode)
          this.$set(this.businessInfoDto, 'addressCode', codeArr.join('-'))
          this.$nextTick(() => {
            this.handleChooseAddress()
          })
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.businessInfoDto = {}
      this.loading = false
      this.info = {}
      this.basicData = {}
    },
    handleOk(key) {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          let formData = { ...this.businessInfoDto }
          formData.addressCode && delete formData.addressCode
          postAction('/business/save/businessInfo', {
            ...formData
          })
            .then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('信息修改成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
            .catch(err => {
              that.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-form-item {
//   margin-bottom: 14px;
//   padding-right: 120px;
// }
::v-deep .ant-input {
  width: 320px;
  height: 38px;
  line-height: 38px;
}

::v-deep .ant-btn {
  width: 97px;
  height: 38px;
}


.text-area-box {
  width: 599px;
  position: relative;

  .counts {
    position: absolute;
    color: #00000073;
    right: 6px;
    bottom: 6px;
  }

  .option-btn {
    position: absolute;
    bottom: -5px;
    right: -68px;
  }
}
</style>
