<!--
 * @Description: main description
 * @Author: zhang zhen
 * @Date: 2023-06-15 17:39:42
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-02-28 11:35:47
 * @FilePath: /page-sass/src/views/businessManagement/companyInfo/modules/moreInfoForm.vue
-->
<template>
  <DrawerView :visible="visible" :width="560" title=" 编辑企业联系人" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model ref="basicForm" :model="businessInfoDto" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
      <a-form-model-item label="联系人"  prop="contact" :rules="{ required: true, message: '请输入公司名称' }">
        <a-input placeholder="请输入" v-model="businessInfoDto.contact"></a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phone" :rules="{ required: true, validator: validatePhone }">
        <a-input placeholder="请输入" v-model="businessInfoDto.phone"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email" :rules="{ required: true, validator: validateEmail }">
        <a-input placeholder="请输入" v-model="businessInfoDto.email"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属部门">
        <j-dictSelect style="width: 320px;height: 38px;" v-model="businessInfoDto.functional"
              dictCode="1046" placeholder="请选择" />
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk('save')" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postQueryAction, postAction } from '@/api/manage'
import JDictSelect from '@/components/plugins/JDictSelect.vue'

export default {
  name: 'moreInfoForm',
  components: {
    DrawerView,
    JDictSelect
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      businessInfoDto: {},
      info: {},
      basicData: {}
    }
  },
  created () {
    let now = new Date().getFullYear()
    this.yearList = []
    for (let i = 0; i < 80; i++) {
      this.yearList.push(now - i)
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    validateEmail(rule, value, callback) {
      let reg = new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    },
    validatePhone(rule, value, callback) {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入有效的手机号'))
      } else {
        callback()
      }
    },
    handleEdit(item) {
      item && (this.basicData = { ...item })
      const { tradeIdentity } = this.setUserInfo()
      this.tradeIdentity = tradeIdentity
      this.handleInitData()
      this.visible = true
    },
    handleInitData() {
      if (!this.setUserInfo().businessId) return
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then(res => {
        const { success, data } = res
        if (success) {
          this.businessInfoDto = data
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.businessInfoDto = {}
      this.loading = false
      this.info = {}
      this.basicData = {}
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          postAction('/business/save/businessInfo', {
            ... this.businessInfoDto,
          })
            .then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('信息修改成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
            .catch(err => {
              that.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-form-item {
//   margin-bottom: 14px;
//   padding-right: 120px;
// }
::v-deep .ant-input {
  width: 320px;
  height: 38px;
  line-height: 38px;
}

::v-deep .ant-btn {
  width: 97px;
  height: 38px;
}

::v-deep .ant-select-selection {
    height: 38px;
  }

  ::v-deep .ant-select-selection__rendered {
    line-height: 38px;
  }
</style>
