<!--
 * @Description: 修改企业简介
 * @Author: zhang zhen
 * @Date: 2023-06-15 17:39:42
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-10 15:25:06
 * @FilePath: /page-sass/src/views/businessManagement/companyInfo/modules/descEditor.vue
-->
<template>
  <DrawerView :visible="visible" :width="800" title="修改企业简介" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model ref="basicForm" :model="businessInfoDto" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">

      <a-form-model-item label="公司简介" :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }">
        <div class="text-area-box">
          <a-textarea placeholder="请输入" :maxLength="500" :auto-size="{ minRows: 4, maxRows: 4 }" style="width: 623px;height: 128px;margin-left: 4px;"
            v-model="businessInfoDto.description" />
          <span class="counts">{{ businessInfoDto.description && businessInfoDto.description.length || 0 }} /
            500</span>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk('save')" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postQueryAction, postAction } from '@/api/manage'
import JDictSelect from '@/components/plugins/JDictSelect.vue'

export default {
  name: 'moreInfoForm',
  components: {
    DrawerView,
    JDictSelect
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      businessInfoDto: {},
      info: {},
      basicData: {}
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEdit(item) {
      item && (this.basicData = { ...item })
      this.handleInitData()
      this.visible = true
    },
    handleInitData() {
      if (!this.setUserInfo().businessId) return
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then(res => {
        const { success, data } = res
        if (success) {
          this.businessInfoDto = data || {}
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.businessInfoDto = {}
      this.loading = false
      this.info = {}
      this.basicData = {}
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          postAction('/business/save/businessInfo', {
            ...this.businessInfoDto,
          })
            .then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('信息修改成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
            .catch(err => {
              that.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-form-item {
//   margin-bottom: 14px;
//   padding-right: 120px;
// }
::v-deep .ant-input {
  width: 320px;
  height: 38px;
  line-height: 38px;
}

::v-deep .ant-btn {
  width: 97px;
  height: 38px;
}


.text-area-box {
  width: 599px;
  position: relative;

  .counts {
    position: absolute;
    color: #00000073;
    right: 6px;
    bottom: 6px;
  }

  .option-btn {
    position: absolute;
    bottom: -5px;
    right: -68px;
  }
}
</style>
